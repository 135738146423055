@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
@import '~@circlon/angular-tree-component/css/angular-tree-component.css';

// Tamam Core
@import "./@tamam/styles/core";


/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @tamam/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
}
@import 'cropperjs/dist/cropper.css';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
button{
  cursor: pointer !important;
}
mat-drawer-container{
  height: 535px;
  min-height: 375px;
  overflow: auto;
}

.input-no-lable{
  top: 24px;
}
.no-border-radius-right .mat-form-field-flex{
  border-radius: 6px 0px 0px 6px !important;
}
.no-border-radius-left .mat-form-field-flex{
  border-radius: 0px 6px 6px 0px!important;
}
.w-id-1{
max-width: 6rem;
}

.mat-paginator-container{
  padding: 0 !important;
}
.mat-paginator .mat-paginator-container .mat-paginator-page-size {
  align-items: center;
  min-height: 40px;
  margin: 0px 10px !important;
}
.card-row5{
  height: 260px !important;
}
.h-custome {
  min-height: 265px !important;
  overflow: auto;
}
.component-header{
  background: #d4dfd775;
  border-radius: 15px 15px 0px 0;
}
.component-header h2{
  text-align: left;
  display: block;
  width: 100%;

}
